// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
    // News Feed Carousel
    if ($(".homeFeed.GetinvolvedFeed").length) {
        $(".homeFeed.GetinvolvedFeed .feedList").slick({
            fade: true,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //add button to each slide article 
        $(".homeFeed.GetinvolvedFeed .feedItem").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".feedItemDetailsWrapper");

            $(carouselDetail).append(`<a href="${href}" class="button">Read more</a>`)
        });
        $(".homeFeed.GetinvolvedFeed").appendTo(".homeFeature.homeBox9");
    }

    $(".listing .headerText .listingIntro").prependTo(".listing .contentBlockWrapper");
});